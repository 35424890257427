import React from "react";
import NavBar from "../components/NavBar";
import "../components/NavBar.css"

function Projects() {
  return (
    <div>
         <NavBar />
      <h2>Projects Page</h2>
    </div>
  );
}

export default Projects;