import React from "react";
import NavBar from "../components/NavBar";
import "../components/NavBar.css"
import Particles from "../components/Particles";
import "./Contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  return (
    <div>
    <div className="contact-info">
      <NavBar />
      <Particles />
      <p>Connect with me on social media.</p>
    </div>
    <div className="social-icons">
      <a href="https://github.com/cade3031/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} size="2x" />
      </a>
      <a href="https://www.linkedin.com/in/cade/-wied-493228287" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </a>
    </div>
  </div>
);
}

export default Contact;