import React from "react"
import "./Footer.css"
import { FaReact,FaJsSquare, FaHtml5, FaCss3Alt } from "react-icons/fa";
import { SiDocker, SiKubernetes, SiGit, SiAzuredevops, SiPostgresql } from "react-icons/si";
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {
    return (
      <div className="footer">
        <ul className="footer-languages">
          <li>
            <FaReact className="react" size={40} alt="React" />
          </li>
          <li>
            <FaJsSquare  className="js" size={40} alt="JavaScript" />
          </li>
          <li>
            <FaHtml5 className="html" size={40} alt="HTML" />
          </li>
          <li>
            <FaCss3Alt className="css" size={40} alt="CSS" />
          </li>
          <li>
            <SiDocker className="dock" size={40} style={{color: "#2496ED"}} alt="Docker" />
          </li>
          <li>
            <SiPostgresql className="postgres" size={40} style={{color: "#336791"}} alt="Postgresql" />
          </li>
          {/* <li> */}
            {/* <SiKubernetes className="k8s" size={40} alt="k8s" /> */}
          {/* </li> */}
          {/* <li>
            <SiGit className="git" size={40} alt="Git" />
          </li> */}
          {/* <li>
            <SiAzuredevops className="azure" size={40} alt="Azure" />
          </li> */}
          <a href="https://github.com/cade3031/"  className="github" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="3x" />
          </a>
          <a href="https://www.linkedin.com/in/cade/-wied-493228287"  className="linkedin" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="3x" />
          </a>
        </ul>
      </div>
    );
  }
  
  export default Footer;