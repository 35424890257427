import React from "react";
import NavBar from "../components/NavBar";
import "../components/NavBar.css";
import "./Home.css";
import Particles from "../components/Particles";
import Footer from "../components/Footer";
import "../components/Footer.css"


function Home() {
  return (
    <div>
      <NavBar />
      <div id="particles-js">
        <Particles /> 
      </div>
      <div className="home-container">
        <div className="intro-paragraph">
          <div className="intro-content">
            <h1>
              Hello, my name is <span className="highlight">Cade Wied</span>
            </h1>
            <h2>
              I'm a <span className="highlight">Full Stack Developer</span>
            </h2>
            <p>
              In the 82nd Airborne Division. I specialize in building dynamic and
              robust web applications using a variety of languages and frameworks.
              My mission is to deliver high-quality, scalable solutions that meet
              the needs of modern users. Feel free to message or follow me on GitHub or Linkedin by clicking the icons below.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
