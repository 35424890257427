// import { Link } from "react-scroll";
import "./NavBar.css";
import logo from "./airborne-innovations-logo.png";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

function NavBar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false); // Close menu after navigation
  };

  return (
    <nav className={`navbar ${isOpen ? "open" : ""}`}>
      <div className="navbar-header">
        <img src={logo} alt="Airborne Innovations Logo" />
        <div className="hamburger-menu" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="navbar-links">
        <button onClick={() => handleNavigation("/Home")}>Home</button>
        <button onClick={() => handleNavigation("/Projects")}>Projects</button>
      </div>
    </nav>
  );
}

export default NavBar;
